<template>
   <div>
        <section class="bg-primary-3 min-vh-100 jarallax d-flex align-items-center text-light" style="display:flex;justify-content:center;align-items:center">
      <img src="/assets/mainBackground.jpg" alt="Image" class="jarallax-img">
      <div v-if="!loading" class="container mt-6" style="width: calc(92%);margin: 0 auto;height: calc(90%);height: calc(75%);position: absolute;display: flex;justify-content: center;align-items: center;border-radius: 60px;">
        
        <div class="firstItemContainer">
          <div class="firstItemContainerLeft">
            <img :src="item.img_src" alt="Image Description" style="border-radius: 0px;height: auto;width: auto;" class="img-fluid">
          </div>
          <div class="firstItemContainerRight">
            <div class="firstItemContainerRightInner">
              
              
              <div class="row container-fluid">
              <div class="col-md-12">
                <div class="itemHeader pt-6">
                  <h4 class="text-dark" style="font-weight: 400;">{{item.title}}
                    <span class="text-right topScore" style="position: ;align-items: end;position: absolute;right: 0px;padding-right: 27px;" v-html="item.topscore">
                     
                    </span>
                  </h4>
                </div>
              </div>
              <div class="col-12">
                <div class="itemHeader" style="" >
                  <p v-html="item.description" >
                    
                  </p>
                </div>
              </div>
            </div>
            
            </div>
            <div class="hoverAddToShelf" v-if="this.$store.getters.loggedIn" style="position:absolute;height:30px;width:30px;top:40px;right:68px;z-index:44;">
                <button @click="addToShelf" class="styleAddToShelf">
                <i class="fa fa-plus-circle" style="color:green;" aria-hidden="true"></i>

                </button>
            </div>
          </div>

        </div>
        <div class="secondItemContainer">
          <div class="row p-4 container-fluid justify-content-center">
            <div v-if="item.producer" class="col-md-3 text-center" style="padding-bottom:2vh;">
              <span class="mb-4">Producer</span>
              <div class="mt-2 " style="width: calc(100% - 10px);background-color: #80419a;display: flex;justify-content: center;align-items: center;border-radius: 27px;">
                <span class="p-2 text-light span" v-html="item.producer"></span>
              </div>
            </div>
            <div v-if="item.country" class="col-md-3 text-center" style="padding-bottom:2vh;">
              <span class="mb-4">Country</span>
              <div class="mt-2 " style="width: calc(100% - 10px);background-color: #80419a;display: flex;justify-content: center;align-items: center;border-radius: 27px;">
                <span class="p-2 text-light span" v-html="item.country"></span>
              </div>
            </div>
            <div v-if="item.region" class="col-md-3 text-center" style="padding-bottom:2vh;">
              <span class="mb-4">Region</span>
              <div class="mt-2 " style="width: calc(100% - 10px);background-color: #80419a;display: flex;justify-content: center;align-items: center;border-radius: 27px;">
                <span class="p-2 text-light span" v-html="item.region"></span>
              </div>
            </div>
            <div v-if="item.varietal" class="col-md-3 text-center" style="padding-bottom:2vh;">
              <span class="mb-4">Grape</span>
              <div class="mt-2 " style="width: calc(100% - 10px);background-color: #80419a;display: flex;justify-content: center;align-items: center;border-radius: 27px;">
                <span class="p-2 text-light span">{{(item.varietal.split(',')[0]).split('/')[0]}}</span>
              </div>
            </div>
            <div v-if="item.size" class="col-md-3 text-center" style="padding-bottom:2vh;">
              <span class="mb-4">Size</span>
              <div class="mt-2 " style="width: calc(100% - 10px);background-color: #80419a;display: flex;justify-content: center;align-items: center;border-radius: 27px;">
                <span class="p-2 text-light span" v-html="item.size"></span>
              </div>
            </div>
            <div v-if="item.style" class="col-md-3 text-center" style="padding-bottom:2vh;">
              <span class="mb-4">Style</span>
              <div class="mt-2 " style="width: calc(100% - 10px);background-color: #80419a;display: flex;justify-content: center;align-items: center;border-radius: 27px;">
                <span class="p-2 text-light span" v-html="item.style"></span>
              </div>
            </div>
            <div v-if="item.body" class="col-md-3 text-center" style="padding-bottom:2vh;">
              <span class="mb-4">Body</span>
              <div class="mt-2 " style="width: calc(100% - 10px);background-color: #80419a;display: flex;justify-content: center;align-items: center;border-radius: 27px;">
                <span class="p-2 text-light span" v-html="item.body"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else><div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                  </div></div>


        <!--
        <div class="vineFooter" style="position: absolute;height: 91px;width: 100%;bottom: 0px;display: flex;justify-content: center;align-content: center;">
          <div class="vineFooterInner">
            <a href="http://www.facebook.com/coolvinesnewark">
            <img class="p-2" src="assets/facebookIcon.png" style="height:4.8rem;width:auto"></a>
            <a href="http://www.instagram.com/coolvineshoboken">
            <img class="p-2" src="assets/instagramIcon.png"style="height:4.8rem;width:auto"></a>
          </div>
        </div>  -->
      
    </section>  
    </div> 
</template>
<script>
import cartItem from "./comps/cartItem";
import axios from 'axios';
export default {
    components: {
        cartItem,
    },
    data() {
        return {
            uri: this.$route.params.slug,
            item: [],
            loading: 'true',
        }
    },
    created() {
        console.log(this.uri);
        console.log('Route: ' + this.$route.name);
        this.fetchItem();
    },
    
    setup() {
        
    },
    methods: {
        fetchItem() {
      axios.get('/api/returnitem?query='+ this.$route.params.slug).then(response => {
          console.log(response.data.item);
          this.item = response.data.item;
          
        }).then(data2 => {
          this.loading = false;
        });
    
    },
    addToShelf() {
            
            this.$store.commit('addToShelf', this.item);
            this.$toasted.success('Added!');
        },
    },
}
</script>
<style scoped>
.firstItemContainer {
        height: calc(64%);
        width: 100%;
        background-color: white;
        margin: 0 auto;
        position: absolute;
        top: 0px;
        display: flex;
        overflow: hidden;
        border-radius: 34px 34px 0 0;
      }
.secondItemContainer {
        height: calc(36%);
        width: 100%;
        background-color: white;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 0 0 34px 34px;
        color: black;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.12rem;
      }
.secondItemContainer .span {
        color: white;
      }
.firstItemContainerLeft {
        height: 100%;
        width: calc(25%);
        
        margin: 0 auto;
        position: absolute;
        
        display: flex;
        justify-content: center;
        align-items: center;
      }
.firstItemContainerRight {
        height: 100%;
        width: calc(75%);
        
        margin: 0 auto;
        position: absolute;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y:auto;
      }
.firstItemContainerRightInner {
        height: calc(90%);
        width: calc(90%);
        
        margin: 0 auto;
        
        
        color: black;
        
        margin: 0 auto;
      }
.topScore >>> img {
        padding-left: 2px;
        padding-right: 2px;
      }
.hoverAddToShelf:hover {
    cursor: pointer;
    scale: 2.2rem;
}
.styleAddToShelf {
  outline: 0ch;
  border: 0px solid white;
  background-color: white;

}
.fa-plus-circle {
  font-size:1.15rem;
}
.fa-plus-circle:hover {
  font-size:1.15rem;
}
</style>